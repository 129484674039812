import React from '../../../node_modules/react';
// Styles
import './Home.scss';


const home = (props) => {

    return (
        <div className={`home-page-layout-new ${props.mobile}`}>
            <div className={`home-title-words-new ${props.mobile}`}>
                Justin Edward Oxford
            </div>
            <div className={`home-col-new`}>

                <div className= {`desc ${props.mobile}`}>
                    <p>Hello & Welcome!</p><p>I am an Analyst & Programmer who's currently working on his pilot's license!</p><p>When not working or flying, I like to take long bike rides, road trips, and sips of coffee.</p>
                </div>
                
                <div className={`btn-cont ${props.mobile}`}>
                    <a className={`btn alt ${props.mobile}`} href='https://github.com/justin-oxford' target='_blank'><div className={`github-logo ${props.mobile}`}/><div className={`content ${props.mobile}`}>Github</div></a>
                </div>

                <div className={`btn-cont ${props.mobile}`}>
                    <a className={`btn ${props.mobile}`} href='https://justinoxford.com/docs/essays/Paper_2_1_22.pdf' target='_blank'><div className={`writing-logo ${props.mobile}`}/><div className={`content ${props.mobile}`}>Transparent A.I.</div></a>
                </div>

                <div className={`btn-cont ${props.mobile}`}>
                    <a className={`btn ${props.mobile}`} href='https://justinoxford.com/docs/essays/Paper_2_1_22.pdf' target='_blank'><div className={`project-logo ${props.mobile}`}/><div className={`content ${props.mobile}`}>Variable Topology</div></a>
                </div>

                <div className={`btn-cont ${props.mobile}`}>
                    <a className={`btn ${props.mobile}`} href='https://justinoxford.com/docs/essays/Logical_Apathy.pdf' target='_blank'><div className={`writing-logo ${props.mobile}`}/><div className={`content ${props.mobile}`}>Logical Apathy</div></a>
                </div>

                <div className={`btn-cont ${props.mobile}`}>
                    <a className={`btn ${props.mobile}`} href='https://justinoxford.com/docs/misc/Laplace_Transforms_Derivative_Conjecture.pdf' target='_blank'><div className={`writing-logo ${props.mobile}`}/><div className={`content ${props.mobile}`}>LaPlace Theorem</div></a>
                </div>

            </div>
        </div>
    );
}
 
export default home;