import React from '../../../node_modules/react';
import './Error.scss';
 
const Error = (props) => {
   return (
      <div className={`page-container ${props.mobile}`}>
         Whoops!<br/>Page Not Found
      </div>
    );
}
 
export default Error;