import React from 'react';
import './App.scss';
import { HashRouter, Route, Routes } from 'react-router-dom';
 
import Home from '../Home/Home';
import ErrorPage from '../Error/Error';
import Footer from '../Footer/Footer';

const mobileORdesktop = (currentMobile) =>
  {
    //console.log(currentMobile);
    if (window.innerWidth <= 900 && currentMobile === "")
    {
      //console.log("mobile");
      return "mobile";
    }
    else if (window.innerWidth > 900 && currentMobile === "mobile")
    {
      //console.log("desktop");
      return "";
    }
  }

const DefaultContainer = (props) =>  (
  <div id="app-main" className={`App-header ${props.mobile}`}>
    {/*<Navigation mobile={`${props.mobile}`} />*/}
    <Routes>
      <Route path="/" 
        element={<Home mobile={`${props.mobile}`} content={props.content} code={props.code} writing={props.writing} />} 
      />

      {/* ERROR PAGE */}
      <Route path="*"
        element={<ErrorPage mobile={`${props.mobile}`}/>}
      />
    </Routes>
    <Footer mobile={`${props.mobile}`} />
  </div>
)

export default function App() {
  const [app_mobile, setMobile] = React.useState("");

  React.useEffect(() => {
    setMobile(() => mobileORdesktop(app_mobile));
    window.addEventListener("resize", () => {
      setMobile(() => mobileORdesktop(app_mobile));
    });
  }, []);

  

  return (      
    <HashRouter>
      <Routes>
        <Route path="/*" element={<DefaultContainer mobile={app_mobile} />}/>
      </Routes>
    </HashRouter>
  );

}
