import React from '../../../node_modules/react';
import './Footer.scss';
 
const Footer = (props) => {
    return (
        <div className={`footer ${props.mobile}`}>
            <div className='ft-cp'>
                Safe Travels
            </div>
            <div className='ft-contact'>
                <span className='ft-subtext'>&copy; 2021</span>
            </div>
        </div>
    );
}
 
export default Footer;